<template>
  <v-dialog
      v-model="dialog"
      width="800px"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
  >
    <v-card>
      <v-toolbar flat>
        <v-card-title>
          <span>{{ title }} what's your interest</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="interest.title"
                  :error="$v.interest.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.interest.title.$error">Title is required</span>
              <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  v-model="interest.type"
                  :error="$v.interest.type.$error"
                  :items="types"
                  item-text="name"
                  item-value="value"
                  clearable
                  outlined
                  dense
              >
                <template v-slot:label>
                  Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.interest.type.$error">Type is required</span>
              <span class="text-danger" v-if="errors.type" >* {{ errors.type[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="interest.class"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Class <span class="text-danger"></span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              Status
              <v-switch
                  v-model="interest.is_active"
                  :label="interest.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
           text
           x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            x-large
            dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import InterestService from "@/services/cms/interest/InterestService";

const interest = new InterestService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      errors: [],
      interest: {
        title: '',
        type: '',
        class: '',
        is_active: true,
      },
      types: [
        {name: 'Online Exam', value: 'online_exam'},
        {name: 'Online Course', value: 'online_course'},
        {name: 'Practical Exam', value: 'practical_exam'},
      ],
    }
  },
  validations: {
    interest: {
      title: { required },
      type: { required },
      is_active: { required }
    }
  },
  methods: {
    closeDialog(){
      this.dialog = false;
      this.resetForm();
    },
    createInterest(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editInterest(interest){
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.interest = interest;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this.create();
        else this.update();
      }
    },
    create: function() {
      interest
          .create(this.interest)
          .then(response =>{
            this.$snotify.success("Interest added");
            this.loading = false;
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      interest
          .update(this.interest.id,this.interest)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Interest updated");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.interest = {
        title: '',
        type: '',
        class: '',
        is_active: true,
      }
    }
  }
}
</script>

<style scoped>

</style>
