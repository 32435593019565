<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>What's your interest </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Interest
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createInterest()"
                      class="mt-1 btn btn-primary"
                      style="color:#fff;"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add interest
                  </v-btn>
                  <v-btn
                      @click="interestSyllabus()"
                      class="mt-1 ml-3 btn btn-primary"
                      style="color:#fff;"
                  >
                    Manage Interest Syllabus
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
               <v-row>
                 <v-col cols="12" sm="6" md="3">
                   <v-text-field
                       label="Title"
                       v-model="search.title"
                       v-on:keyup.enter="searchInterest"
                       @input="search.title = $event !== null ? $event : ''"
                       clearable
                       outlined
                       dense
                   ></v-text-field>
                 </v-col>

                 <v-col cols="12" sm="6" md="3">
                   <v-select
                       label="Type"
                       v-model="search.type"
                       :items="types"
                       item-text="name"
                       item-value="value"
                       v-on:keyup.enter="searchInterest"
                       @input="search.type = $event !== null ? $event : ''"
                       clearable
                       outlined
                       dense
                   ></v-select>
                 </v-col>

                 <v-col cols="12" sm="6" md="3">
                   <v-select
                       label="Status"
                       v-model="search.is_active"
                       v-on:keyup.enter="searchInterest"
                       @input="search.is_active = $event !== null ? $event : ''"
                       :items="status"
                       item-text="name"
                       item-value="value"
                       clearable
                       outlined
                       dense
                   ></v-select>
                 </v-col>
                 <v-col cols="12" sm="6" md="3" class="text-right">
                   <v-btn
                       @click.prevent="searchInterest"
                       v-on:keyup.enter="searchInterest"
                       class="btn btn-primary"
                       style="color: #fff;"
                       :loading="loading"
                   >
                     <v-icon small  outlined>fas fa-search</v-icon>&nbsp;
                     Search
                   </v-btn>
                 </v-col>
               </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Title</th>
                  <th class="px-3">Type</th>
                  <th class="px-3">Position</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                  <draggable class="w-100" v-model="interests" @change="sort" @start="drag=true" tag="tbody" @end="drag=false">
                    <tr v-for="(interest, index) in interests" :key="index">
                      <td class="px-3">
                        <a @click="editInterest(interest)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                          <i class="fa fa-sort"></i>  {{ interest.title }}
                        </a>
                      </td>
                      <td class="px-3">
                        {{ interest.type_text }}
                      </td>
                      <td class="px-3">
                        {{ interest.position }}
                      </td>
                      <td class="px-3">
                          <span class="badge"
                                v-bind:class="{ 'badge-success': interest.is_active, 'badge-danger': !interest.is_active }"
                          >{{ interest.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click="editInterest(interest)">
                                    <span class="navi-icon">
                                        <i class="fas fa-edit"></i>
                                    </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteInterest(interest.id)">
                                    <span class="navi-icon">
                                       <i class="fas fa-trash"></i>
                                    </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="interests.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="6">No Data Found</td>
                    </tr>
                  </draggable>
                </template>

              </table>

            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>

          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllInterests"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import InterestService from "@/services/cms/interest/InterestService";
import draggable from "vuedraggable";

const interest = new InterestService();
export default {
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return{
      interests:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      drag: false,
      isSorting: false,
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      types: [
        {name: 'Online Exam', value: 'online_exam'},
        {name: 'Online Course', value: 'online_course'},
        {name: 'Practical Exam', value: 'practical_exam'},
      ],
      search:{
        title:'',
        is_active:'',
      }
    }
  },
  mounted() {
    this.getAllInterests();
  },
  methods: {
    interestSyllabus(){
      this.$router.push({
        name: "interest-syllabus",
      });
    },
    createInterest(){
      this.$refs['create-and-update'].createInterest();
    },
    editInterest(interest){
      this.$refs['create-and-update'].editInterest(interest);
    },
    getAllInterests(){
      this.loading = true;
      interest
      .paginate(this.search, this.page)
      .then(response => {
        this.interests = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    sort(){
      this.isSorting = true;
      interest
          .sort(this.interests)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Interest Sorted !!');
            this.getAllInterests();
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchInterest(){
      this.getAllInterests();
    },
    deleteInterest(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            interest
                .delete(id)
                .then((response) => {
                  this.getAllInterests()
                  this.$snotify.success("Interest deleted ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  }
}
</script>